import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ServiceUnavailableComponent} from '../modules/error-pages/service-unavailable/service-unavailable.component';
import {DefaultComponent} from '../templates/default/default.component';
import {BetriebNotFoundComponent} from '../modules/error-pages/betrieb-not-found/betrieb-not-found.component';


const routes: Routes = [
  {
    path: 'service-unavailable',
    component: DefaultComponent,
    children: [
      {
        path: '',
        component: ServiceUnavailableComponent,
      },
    ]
  },
  {
    path: 'betrieb-not-found',
    component: DefaultComponent,
    children: [
      {
        path: '',
        component: BetriebNotFoundComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorPagesRoutingModule {
}
