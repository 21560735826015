import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'dopo-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})

/**
 * @description Die Verwendung von dynamischen Farben in Icons. Du kannst bist zu zwei Farben definieren:
 * Primär und Akzent. Du kannst sowohl die Theme-Notationen "primary", "accent" und "warn" verwenden, aber auch
 * jeden CSS Code, der sich zur Farbdefinition eignet.
 *
 * Vorbereitung: Um die dynamischen Farben nutzen zu können, müssen in dem SVG-File alle "fill" und "stroke" Attribute
 * entfernt werden. Setze an allen Stellen, die die Accent-Farbe erhalten sollen eine Akzent-Klasse, z.B.:
 * ```
 * <circle cx="12" cy="12" r="9" class="accent" />
 * ```
 * Alle übrigen Elemente erhalten per default die primary Farbe.
 */

export class IconComponent implements OnInit {

  // INFO: Name des Icons. Muss mit dem Namen der SVG Datei übereinstimmen.
  @Input()
  icon!: string;

  // INFO: Primärfarbe für Strokes
  @Input()
  primaryStroke = 'primary';

  // INFO: Akzentfarbe für Strokes. Falls nicht gesetzt, wird die Primärfarbe als Fallback verwendet.
  @Input()
  accentStroke?: string;

  // INFO: Primärfarbe für fill.
  @Input()
  primaryFill = 'none';

  // INFO: Akzentfarbe für fill. Falls nicht gesetzt, wird die Primärfarbe als Fallback verwendet.
  @Input()
  accentFill?: string;

  // INFO: Erste Farbe für einen Farbverlauf.
  @Input()
  gradient1?: string;

  // INFO: Zweite Farbe für einen Farbverlauf.
  @Input()
  gradient2?: string;

  // INFO: Hier sind einige Variablen, um die Daten in das Template und das CSS-File zu übertragen.
  public primaryStrokeClass = '';
  public accentStrokeClass = '';
  public primaryFillClass = '';
  public accentFillClass = '';
  public gradient1Class = '';
  public gradient2Class = '';

  public primaryStrokeStyle = '';
  public accentStrokeStyle = '';
  public primaryFillStyle = '';
  public accentFillStyle = '';
  public gradient1Style = '';
  public gradient2Style = '';

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {

    /*
     * INFO: Wenn die Primärfarbe für Stroke gesetzt wurde, wird geprüft, ob es sich um eine gültige
     * Theme-Notation handelt. Ist das der Fall, wird eine Klasse gesetzt, sodass die Farbe vom Theme gesteuert wird.
     * Andernfalls wird die Farbe in das CSS-File injected und dort per Variable gesetzt.
     */
    if (this.primaryStroke) {
      switch (this.primaryStroke) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.primaryStrokeClass = this.primaryStroke + '-primaryStroke';
          break;
        default:
          this.primaryStrokeStyle = this.primaryStroke;
      }
    }

    /*
     * INFO: Wenn die Akzentfarbe für Stroke gesetzt wurde, wird geprüft, ob es sich um eine gültige
     * Theme-Notation handelt. Ist das der Fall, wird eine Klasse gesetzt, sodass die Farbe vom Theme gesteuert wird.
     * Andernfalls wird die Farbe in das CSS-File injected und dort per Variable gesetzt.
     */
    if (this.accentStroke) {
      switch (this.accentStroke) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.accentStrokeClass = this.accentStroke + '-accentStroke';
          break;
        default:
          this.accentStrokeStyle = this.accentStroke;
      }
    }

    /*
     * INFO: Wenn die Primärfarbe für Fill gesetzt wurde, wird geprüft, ob es sich um eine gültige
     * Theme-Notation handelt. Ist das der Fall, wird eine Klasse gesetzt, sodass die Farbe vom Theme gesteuert wird.
     * Andernfalls wird die Farbe in das CSS-File injected und dort per Variable gesetzt.
     */
    if (this.primaryFill) {
      switch (this.primaryFill) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.primaryFillClass = this.primaryFill + '-primaryFill';
          break;
        default:
          this.primaryFillStyle = this.primaryFill;
      }
    }

    /*
     * INFO: Wenn die Akzentfarbe für Fill gesetzt wurde, wird geprüft, ob es sich um eine gültige
     * Theme-Notation handelt. Ist das der Fall, wird eine Klasse gesetzt, sodass die Farbe vom Theme gesteuert wird.
     * Andernfalls wird die Farbe in das CSS-File injected und dort per Variable gesetzt.
     */
    if (this.accentFill) {
      switch (this.accentFill) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.accentFillClass = this.accentFill + '-accentFill';
          break;
        default:
          this.accentFillStyle = this.accentFill;
      }
    }

    /*
     * INFO: Wenn die erste Farbe für den Verlauf gesetzt wurde, wird geprüft, ob es sich um eine gültige
     * Theme-Notation handelt. Ist das der Fall, wird eine Klasse gesetzt, sodass die Farbe vom Theme gesteuert wird.
     * Andernfalls wird die Farbe in das CSS-File injected und dort per Variable gesetzt.
     */
    if (this.gradient1) {
      switch (this.gradient1) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.gradient1Class = this.gradient1 + '-gradient1';
          break;
        default:
          this.gradient1Style = this.gradient1;
      }
    }

    /*
     * INFO: Wenn die zweite Farbe für den Verlauf gesetzt wurde, wird geprüft, ob es sich um eine gültige
     * Theme-Notation handelt. Ist das der Fall, wird eine Klasse gesetzt, sodass die Farbe vom Theme gesteuert wird.
     * Andernfalls wird die Farbe in das CSS-File injected und dort per Variable gesetzt.
     */
    if (this.gradient2) {
      switch (this.gradient2) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.gradient2Class = this.gradient2 + '-gradient2';
          break;
        default:
          this.gradient2Style = this.gradient2;
      }
    }

    /*
     * INFO: Die vorbereiteten Farben werden in das CSS-File injected, die dort als Variable verwendet werden können.
     */
    this.elementRef.nativeElement.style.setProperty('--primary-stroke-style', this.primaryStrokeStyle);
    this.elementRef.nativeElement.style.setProperty('--accent-stroke-style', this.accentStrokeStyle);
    this.elementRef.nativeElement.style.setProperty('--primary-fill-style', this.primaryFillStyle);
    this.elementRef.nativeElement.style.setProperty('--accent-fill-style', this.accentFillStyle);
    this.elementRef.nativeElement.style.setProperty('--gradient1-style', this.gradient1Style);
    this.elementRef.nativeElement.style.setProperty('--gradient2-style', this.gradient2Style);

  }
}


@Component({
  selector: 'dopo-icon',
  template: '<span></span>'
})
export class IconTestingComponent {
  @Input()
  icon: any;

  @Input()
  primaryStroke: any;

  @Input()
  accentStroke: any;

  @Input()
  primaryFill: any;

  @Input()
  accentFill: any;
}
