import {NgModule} from '@angular/core';
import {IconModule} from '../icon/icon.module';
import {ServiceUnavailableComponent} from './service-unavailable/service-unavailable.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {ErrorPagesRoutingModule} from '../../routes/error-pages-routing.module';
import {BetriebNotFoundComponent} from './betrieb-not-found/betrieb-not-found.component';


@NgModule({
    imports: [
        IconModule,
        MatButtonModule,
        ErrorPagesRoutingModule,
    ],
    declarations: [
        ServiceUnavailableComponent,
        BetriebNotFoundComponent,
    ],
    exports: [
        BetriebNotFoundComponent
    ]
})
export class ErrorPagesModule {
}
