import {Component} from '@angular/core';

@Component({
  selector: 'dopo-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent {

  constructor() {
  }
}
